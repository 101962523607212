import { useState, useEffect } from "react";
import { NavLink, useLocation, Link } from "react-router-dom";
import { NavView, MenuView, AppLogo, RecordLink } from "./style";
import classNames from "classnames";
import { themes } from "@/styles/globalStyle.js";

const LogoDark =
  "https://huosign.oss-cn-shanghai.aliyuncs.com/images/home/app-logo-dark.png";
const LogoLight =
  "https://huosign.oss-cn-shanghai.aliyuncs.com/images/home/app-logo-light.png";
const logo =
  "https://huosign.oss-cn-shanghai.aliyuncs.com/images/home/logo.png";

let navUl = [
  {
    path: "/",
    name: "首页",
  },
  {
    path: "/cooperationPage",
    name: "商务合作",
  },
  {
    path: "/aboutUsPage",
    name: "关于我们",
  },
];

const NavBar = ({}) => {
  const location = useLocation();
  const [pathName, setPathName] = useState(location.pathname); //url地址
  const [theme, setTheme] = useState(themes.light); //nav主题色
  //当路由发生变化时
  useEffect(() => {
    if (location.pathname === "/") setTheme(themes.dark);
    setPathName(location.pathname);
    return () => {
      setTheme(themes.light);
    };
  }, [location.pathname]);
  //换主题色
  useEffect(() => {
    for (const value in theme) {
      document.documentElement.style.setProperty(`--${value}`, theme[value]);
    }
  }, [theme]);

  //导航按钮
  const NavItem = ({ path, name }) => {
    return (
      <Link
        className={classNames({
          "menu-item": true,
          active: pathName === path,
        })}
        to={path}
      >
        {name}
      </Link>
    );
  };

  return (
    <NavView name={pathName}>
      {/* app图标 */}
      <h1 className="logo">
        <a
          href="https://www.huosign.com/"
          className="logo-a"
          style={{ background: `url(${logo}) 0 0 / contain no-repeat` }}
          title="火象社区"
        >
          火象社区
        </a>
        <NavLink to={"/"} className="logo-x" title="火象社区">
          {/* <img width={"54"} height={"35"} src={LogoImg} alt="火象社区" /> */}
          <AppLogo
            src={pathName === "/" ? LogoLight : LogoDark}
            alt="火象社区"
          />
        </NavLink>
      </h1>
      {/* 导航列表 */}
      <MenuView>
        {navUl.map((item) => (
          <NavItem {...item} key={item.name} />
        ))}

        <Link
          className={`menu-item trader-nav-item`}
          to={"/huosign-trader/home"}
        >
          百万交易员
        </Link>

        <div className="ext-x" data-hover={pathName === "/" ? 0 : 1}>
          <a
            href="https://www.huosign.com/crm/#/login"
            className="ext-a"
            target="_blank"
          >
            <RecordLink
              className="write-btn"
              data-hover={pathName === "/" ? 0 : 1}
            >
              机构中心
            </RecordLink>
          </a>
          <a
            href="https://www.huosign.com/ltd/#/login"
            className="ext-a"
            target="_blank"
          >
            <RecordLink
              className="pro-btn"
              data-hover={pathName === "/" ? 0 : 1}
            >
              火象pro
            </RecordLink>
          </a>
        </div>
      </MenuView>
    </NavView>
  );
};

export default NavBar;
