import styled from "styled-components";

export const NavView = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 86px;
  background-color: var(--navBgc);
  box-shadow: ${(props) =>
    props.name === "/home" ? "none" : "0px 3px 4px rgba(0, 0, 0, 0.05)"};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  /* padding: 0 8.81% 0 9.72%; */
  padding: 0 9.72% 0 9.72%;
  z-index: 10;
  .logo-x {
    display: flex;
    align-items: center;
    text-indent: -999px;
    overflow: hidden;
  }
  .logo-a {
    position: absolute;
    display: block;
    width: 280px;
    height: 128px;
    background-size: contain;
    font-size: 0;
    text-indent: -999px;
    overflow: hidden;
    margin-left: -9999px;
  }
`;
export const MenuView = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: var(--navColor);
  white-space: nowrap;

  .trader-nav-item {
    position: relative;
    color: #ffcf4d !important;
    margin-left: 58px;
    &::before {
      position: absolute;
      top: 46%;
      left: -28px;
      width: 28px;
      height: 28px;
      transform: translateY(-50%);
      background: url("https://traders-contest.oss-cn-shanghai.aliyuncs.com/traderContestPC/home-trader-nav-icon.png")
        0 0 / 100% 100% no-repeat;
      content: "";
    }

    &:hover,
    &:hover::after,
    &.active,
    &.active::after {
      border-color: #ffcf4d !important;
      opacity: 1;
    }
  }

  .menu-item {
    position: relative;
    white-space: nowrap;
    padding: 10px 0;
    color: var(--navColor);
    transition: all 0.2s;
  }
  .menu-item::after {
    position: absolute;
    bottom: 3px;
    left: 50%;
    width: 10px;
    height: 0;
    border-bottom: 2px solid var(--navActiveColor);
    border-radius: 2px;
    margin-left: -5px;
    opacity: 0;
    content: "";
    /* transition: all 0.1s linear; */
  }
  .menu-item:hover,
  .menu-item:hover::after,
  .menu-item.active,
  .menu-item.active::after {
    font-weight: bold;
    color: var(--navActiveColor);
    border-color: var(--navActiveColor);
    opacity: 1;
  }
  .menu-item:not(:first-child) {
    margin-left: 50px;
  }
  .ext-x {
    position: relative;
    display: inline-block;
    line-height: 40px;
    margin-left: 72px;
  }
  .ext-x::before {
    position: absolute;
    top: 50%;
    left: -37px;
    transform: translateY(-50%);
    width: 0;
    height: 16px;
    border-left: 1px solid #666666;
    /* content: ""; */
  }
  .ext-x[data-hover="1"]::before {
    border-left-color: #cccccc;
  }
  .ext-a + .ext-a {
    margin-left: 10px;
  }
  .pro-btn::before,
  .write-btn::before {
    width: 18px;
    height: 18px;
    display: inline-block;
    margin-right: 5px;
    transform: translateY(3px);
    background-size: 67px auto;
    background-repeat: no-repeat;
    background-image: url("https://huosign.oss-cn-shanghai.aliyuncs.com/images/home/nav-s-icon.png");
    content: "";
  }
  .pro-btn::before {
    background-position: -36px -54px;
  }
  .write-btn::before {
    background-position: -10px -54px;
  }
  .pro-btn[data-hover="1"]::before {
    background-position: -36px -34px;
  }
  .write-btn[data-hover="1"]::before {
    background-position: -10px -34px;
  }
  .pro-btn:hover,
  .write-btn:hover {
    font-weight: 400;
  }
  .write-btn[data-hover="0"]:hover::before {
    background-position: -10px -10px;
  }
  .write-btn[data-hover="1"]:hover::before {
    background-position: -10px -54px;
  }
  .pro-btn[data-hover="0"]:hover::before {
    background-position: -36px -10px;
  }
  .pro-btn[data-hover="1"]:hover::before {
    background-position: -36px -54px;
  }
  .ml50 {
    margin-left: 50px;
  }
  .ml25 {
    margin-left: 12px;
  }
`;
export const AppLogo = styled.img`
  width: 121px;
  height: 75px;
  /* width: 102px;
  height: 25px; */
`;
export const RecordLink = styled.div`
  position: relative;
  width: 110px;
  font-weight: 400;
  /* padding: 10px 0; */
  text-align: center;
  background: var(--recordBtnBg);
  color: var(--recordBtnColor);
  border-radius: 6px;
  /* margin-left: 50px; */
  display: inline-block;
  &:hover {
    color: var(--recordHoverBtnColor);
    background: var(--recordHoverBtnBg);
  }
  .record-icon {
    width: 13px;
    height: 13px;
    display: inline-block;
    margin-right: 7px;
  }
`;
