import React, { memo, Suspense, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import routes from "@/router";
import MobHintPopUpView from "@/components/mobile/MobHintPopUpView";
import PageLoading from "@/components/common/PageLoading";
import NavBar from "@/components/common/NavBar";
import { verifyEquipment, downloadPage, isH5Sharepage } from "@/utils/comFunc";
export default withRouter(
  memo(function App({ location }) {
    const isH5 = verifyEquipment();
    useEffect(() => {
      //是否移动端进入官网页面, 判断是否继续加载官网页面
      if (
        isH5 &&
        (location.pathname.indexOf("mob") !== -1 ||
          location.pathname.indexOf("trader") !== -1 ||
          location.pathname.indexOf("userPolicy/privacy") !== -1 ||
          location.pathname.indexOf("userPolicy/protocol") !== -1 ||
          location.pathname.indexOf("huosign-api") !== -1)
      )
        return;
      downloadPage(location.pathname);
    }, []);
    //当路由发生变化
    useEffect(() => {
      //页面滚动到顶部
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      //获取页面title
      let _title = "";
      for (let i = 0; i < routes.length; i++) {
        let item1 = routes[i],
          _path = location.pathname;
        if (!!item1.children && item1.children.length > 0) {
          for (let j = 0; j < item1.children.length; j++) {
            let item2 = item1.children[j];
            if (item2.path === _path) _title = item2.title;
          }
        }
        if (item1.path === _path) _title = item1.title;
      }
      //设置当前页面title
      //判断是不是移动端的大赛
      if (
        location.pathname.indexOf("trader") === -1 ||
        location.pathname.indexOf("huosign-trader") !== -1
      ) {
        document.title = _title;
      }

      //发送埋点数据
      const { aplus_queue } = window;
      aplus_queue.push({
        action: "aplus.sendPV",
        arguments: [{ is_auto: false }, [{ Um_Key_PageName: _title }]],
      });
    }, [location.pathname]);
    return (
      <div className="App">
        {!isH5Sharepage(location.pathname) && <NavBar />}
        <Suspense
          fallback={
            isH5 ? (
              <MobHintPopUpView type="loading" _time="1" />
            ) : (
              <PageLoading></PageLoading>
            )
          }
        >
          {renderRoutes(routes)}
        </Suspense>
      </div>
    );
  })
);
